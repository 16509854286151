// Init
import React from 'react';

// Importing Components
import Navbar from '../components/Common/Navbar';
import ContactSection from '../components/Common/ContactSection';
import Footer from '../components/Common/Footer';
// Home Component
export default function ContactUs() {
  return (
    <div>
      <Navbar />
      <ContactSection />
      <Footer />
    </div>
  );
}
