// Init
import React from "react";
import { Link } from "react-router-dom";
// images
import { ReactComponent as Arrow } from "../../assets/Images/about_section_arrow.svg";
import Mouse from "../../assets/Images/Mouse.gif";
// Component
export default function Header(props) {
  const { floating } = props;
  return (
    <div className="homeHeaderContainer">
      {floating && (
        <div className="homeHeaderFloatingBtnDiv">
          <button className="freelancer">Freelancer</button>
          <button className="upwork">Upwork</button>
          <button className="fiverr">Fiverr</button>
          {/* <button className="whatsapp">Whatsapp</button>
          <button className="facebook">Facebook</button>
          <button className="linkedin">Linkedin</button> */}
        </div>
      )}
      <div className="homeHeaderTextDiv">
        <h2 className="homeHeaderTitle">
          Amplify Your <br /> business's potential with <br /> Staff augmentation
        </h2>
        <p className="homeHeaderDesp">
          Connect with EverGreenSoft's Sales Whiz for customized business solutions.
        </p>
        <Link to="/contactus" className="homeHeaderBtnDiv">
          <button className="homeHeaderBtn">Hire Talent free for 1 day </button>
          <div className="homeHeaderBtnArrow">
            <Arrow color="#1e1e1e" />
          </div>
        </Link>
        <div className="homeHeaderMouseDiv">
          <img className="homeHeaderMouseGif" src={Mouse} alt="gif" />
        </div>
      </div>
    </div>
  );
}
