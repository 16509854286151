// Init
import React, { useRef, useState } from "react";
// images
import BgTitle from "../../assets/Images/contact_section_bg_title.svg";
import { ReactComponent as Arrow } from "../../assets/Images/about_section_arrow.svg";

import Call from "../../assets/Images/contact_callus.svg";
import Email from "../../assets/Images/contact_mail.svg";
import { Grid } from "@mui/material";
import validate from "validate.js";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

// Component
export default function ContactSection() {
  const form = useRef();

  const [activeName, setActiveName] = useState("");

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: "",
      phone: "",
      company: "",
      email: "",
      message: "",
    },
    touched: {},
    errors: {},
  });
  const schema = {
    name: {
      presence: { allowEmpty: false, message: "is required" },
      // message: 'must start with 0',
      length: {
        minimum: 3,
        message: "atleast 3 characters",
        // maximum: 40,
      },
    },

    phone: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        minimum: 10,

        message: "atleast 10 digits long",
      },
    },
    company: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 60,
      },
    },
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: {
        attribute: "true",
        message: "format (name@domain.com)",
      },

      length: {
        maximum: 60,
      },
    },
    message: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 1000,
      },
    },
  };
  const resetForm = () => {
    setFormState({
      isValid: false,
      values: {
        name: "",
        phone: "",
        company: "",
        email: "",
        message: "",
      },
      touched: {},
      errors: {},
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs
    //   .sendForm(
    //     "service_6pyog6s",
    //     "template_h9k45v8",
    //     form.current,
    //     "WGQaljk2rP5mRxEJ-"
    //   ) // Replace placeholders with actual IDs
    //   .then(
    //     (result) => {
    //       resetForm();
    //       console.log("Email sent successfully:", result);
    //       // Additional success handling if needed
    //     },
    //     (error) => {
    //       console.error("Error sending email:", error);
    //       // Additional error handling if needed
    //     }
    //   );

    emailjs
      .sendForm(
        "service_6pyog6s",
        "template_h9k45v8",
        e.target,
        "WGQaljk2rP5mRxEJ-"
      )
      .then(
        (result) => {
          resetForm();
          return result;
        },
        (error) => {
          return error;
        }
      );

    resetForm();
    toast.success(
      "Thank you, the information has been submitted successfully. We will come back to you shortly."
    );
  };
  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  const blurHandler = (e) => {
    e.preventDefault();
    setActiveName("");
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
      errors: errors || {},
    }));
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <>
      <div className="contactSectionContainer">
        <div className="contactSectionBgTitleDiv">
          <img src={BgTitle} />
        </div>
        <div className="contactSectionMainDiv">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
            className="contactSectionHeaderDiv"
          >
            <h2>Contact us</h2>
            <p>
              Reach Out to Us Now and Receive a Free Consultation from Our Ever
              Green Sales Whiz
            </p>
          </motion.div>
          <div className="contactSectionContentDiv">
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.3 }}
              viewport={{ once: true }}
              className="contactSectionLeftContent"
            >
              <div className="contactSectionLinksHeadingDiv">
                <h2>Get in Touch</h2>
                <p>Tell us More about you and we’ll contact you soon</p>
              </div>
              <div className="contactSectionLinksDiv">
                <a href="tel:313-509-8197" className="contactSectionLink">
                  <img src={Call} />
                  <div className="contactSectionLinkText">
                    <p>Call us</p>
                    <span>+92 313 5098197</span>
                  </div>
                </a>
                <a
                  href="mailto:evgreen.soft@gmail.com"
                  className="contactSectionLink"
                >
                  <img src={Email} />
                  <div className="contactSectionLinkText">
                    <p>Email</p>
                    <span>evgreen.soft@gmail.com</span>
                  </div>
                </a>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              viewport={{ once: true }}
              className="contactSectionRightContent"
            >
              <form onSubmit={sendEmail} className="contactSectionFormDiv">
                <Grid
                  className="contactSectionFormContainer"
                  container
                  spacing={1}
                >
                  <Grid className="contactSectionFormGrid" xs={12} sm={5.8}>
                    <div className="contactSectionFormInputDiv">
                      <label
                        style={{
                          color:
                            activeName === "name"
                              ? "#83FD9E"
                              : !hasError("name") &&
                                formState.values.name === ""
                              ? "#f8f8f8"
                              : hasError("name")
                              ? "#FF603D"
                              : "#B7B7B7",
                        }}
                      >
                        Name
                      </label>
                      <input
                        autocomplete="off"
                        maxLength="64"
                        name="name"
                        onChange={handleChange}
                        onBlur={(e) => blurHandler(e)}
                        onFocus={(e) => setActiveName(e.target.name)}
                        // className='New_ContactSection_Input'
                        value={formState.values.name}
                        style={{
                          borderBottom:
                            activeName === "name"
                              ? "1px solid #83FD9E"
                              : hasError("name")
                              ? "1px solid #83FD9E"
                              : "1px solid #83FD9E",
                        }}
                        placeholder="Your Name"
                      />
                      {hasError("name") && (
                        <div className="formValidationError">
                          {formState.errors.name[0]}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid className="contactSectionFormGrid" xs={12} sm={5.8}>
                    <div className="contactSectionFormInputDiv">
                      <label
                        style={{
                          color:
                            activeName === "company"
                              ? "#83FD9E"
                              : !hasError("company") &&
                                formState.values.company === ""
                              ? "#f8f8f8"
                              : hasError("company")
                              ? "#FF603D"
                              : "#B7B7B7",
                        }}
                      >
                        Company
                      </label>
                      <input
                        autocomplete="off"
                        name="company"
                        maxLength="60"
                        value={formState.values.company}
                        onChange={handleChange}
                        onBlur={(e) => blurHandler(e)}
                        onFocus={(e) => setActiveName(e.target.name)}
                        style={{
                          borderBottom:
                            activeName === "company"
                              ? "1px solid #83FD9E"
                              : hasError("company")
                              ? "1px solid #83FD9E"
                              : "1px solid #83FD9E",
                        }}
                        placeholder="Your Company"
                      />
                      {hasError("company") && (
                        <div className="formValidationError">
                          {formState.errors.company[0]}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid className="contactSectionFormGrid" xs={12} sm={5.8}>
                    <div className="contactSectionFormInputDiv">
                      <label
                        style={{
                          color:
                            activeName === "email"
                              ? "#83FD9E"
                              : !hasError("email") &&
                                formState.values.email === ""
                              ? "#f8f8f8"
                              : hasError("email")
                              ? "#FF603D"
                              : "#B7B7B7",
                        }}
                      >
                        Email
                      </label>
                      <input
                        autocomplete="off"
                        maxLength="60"
                        name="email"
                        value={formState.values.email}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => blurHandler(e)}
                        onFocus={(e) => setActiveName(e.target.name)}
                        style={{
                          borderBottom:
                            activeName === "email"
                              ? "1px solid #83FD9E"
                              : hasError("email")
                              ? "1px solid #83FD9E"
                              : "1px solid #83FD9E",
                        }}
                        placeholder="acb@xyz.com"
                      />
                      {hasError("email") && (
                        <div className="formValidationError">
                          {formState.errors.email[0]}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid className="contactSectionFormGrid" xs={12} sm={5.8}>
                    <div className="contactSectionFormInputDiv">
                      <label
                        style={{
                          color:
                            activeName === "phone"
                              ? "#83FD9E"
                              : !hasError("phone") &&
                                formState.values.phone === ""
                              ? "#f8f8f8"
                              : hasError("phone")
                              ? "#FF603D"
                              : "#B7B7B7",
                        }}
                      >
                        Phone
                      </label>
                      <input
                        autocomplete="off"
                        name="phone"
                        value={formState.values.phone}
                        maxLength="13"
                        onChange={(e) => {
                          // check input using regex
                          var regex = RegExp(/^\d+$/);
                          const test_Result = regex.test(e.target.value);
                          if (test_Result || e.target.value == "") {
                            handleChange(e);
                          }
                        }}
                        onBlur={(e) => blurHandler(e)}
                        onFocus={(e) => setActiveName(e.target.name)}
                        style={{
                          borderBottom:
                            activeName === "phone"
                              ? "1px solid #83FD9E"
                              : hasError("phone")
                              ? "1px solid #83FD9E"
                              : "1px solid #83FD9E",
                        }}
                        placeholder="Enter Number"
                      />
                      {hasError("phone") && (
                        <div className="formValidationError">
                          {formState.errors.phone[0]}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid className="contactSectionFormGrid" xs={12}>
                    <div className="contactSectionFormInputDiv">
                      <label
                        style={{
                          color:
                            activeName === "message"
                              ? "#83FD9E"
                              : !hasError("message") &&
                                formState.values.message === ""
                              ? "#f8f8f8"
                              : hasError("message")
                              ? "#FF603D"
                              : "#B7B7B7",
                        }}
                      >
                        Message
                      </label>
                      <textarea
                        autoComplete="off"
                        name="message"
                        maxLength="1000"
                        value={formState.values.message}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => blurHandler(e)}
                        onFocus={(e) => setActiveName(e.target.name)}
                        style={{
                          borderBottom:
                            activeName === "message"
                              ? "1px solid #83FD9E"
                              : hasError("message")
                              ? "1px solid #83FD9E"
                              : "1px solid #83FD9E",
                        }}
                        rows={1}
                        placeholder="Type something..."
                      />
                      {hasError("message") && (
                        <div className="formValidationError">
                          {formState.errors.message[0]}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <div className="contactSectionBtnContainer">
                  <div
                    className={
                      !formState.isValid
                        ? "contactSectionBtnDiv_Disable"
                        : "contactSectionBtnDiv"
                    }
                  >
                    <button
                      disabled={formState.isValid ? false : true}
                      className={
                        !formState.isValid
                          ? "contactSectionBtn_Disable "
                          : "contactSectionBtn"
                      }
                    >
                      Submit
                    </button>
                    <Arrow
                      className={
                        !formState.isValid
                          ? "contactSectionBtnImg_Disable"
                          : "contactSectionBtnImg"
                      }
                    />
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
