// Init
import React from "react";
// images
// import About from "../../assets/Images/about_section_home.svg";
// import Arrow from "../../assets/Images/about_section_arrow.svg";
// import VisionBg from "../../assets/Images/about_vision_bg.svg";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
import img1 from "../../assets/img/image.png";
import img2 from "../../assets/img/image2.png";
import img3 from "../../assets/img/image3.png";
import img4 from "../../assets/img/image5.png";
import img5 from "../../assets/img/image4.png";

// Component
export default function Portfolio() {
  return (
    <>
      <div className="PortfoioContainer">
        <p className="portfolio_heading_txt">Portfolio</p>

        <div className="Portfoio_inner">
          <div className="img_one_div">
            <img src={img1} />
            <div className="portfolio_text_holder">
              <div className="portfolio_text_wrapper">
                <p className="portfolio_text">
                  <a href="https://create.walltonez.com/">Soundwave</a>
                </p>
                {/* <p className="portfolio_cat">img1</p> */}
              </div>
            </div>
          </div>

          <div className="image_twoMainDiv">
            <div className="img_two_div">
              <img src={img2} />
              <div className="portfolio_text_holder">
                {" "}
                <div className="portfolio_text_wrapper">
                  <p className="portfolio_text">
                    <a href="https://ezyevent-dev.falconweb.app/">EzyEvent</a>
                  </p>
                  {/* <p className="portfolio_cat">img1</p> */}
                </div>
              </div>
            </div>
            <div className="image_Div_twoCol">
              <div className="img_three_div">
                <img src={img3} />
                <div className="portfolio_text_holder">
                  {" "}
                  <div className="portfolio_text_wrapper">
                    <p className="portfolio_text">
                      <a href="https://play.google.com/store/apps/details?id=com.soft_sun_26825">
                        Hip Hop My Way
                      </a>
                    </p>
                    {/* <p className="portfolio_cat">img1</p> */}
                  </div>
                </div>
              </div>
              <div className="img_four_div">
                <img src={img4} />
                <div className="portfolio_text_holder">
                  {" "}
                  <div className="portfolio_text_wrapper">
                    <p className="portfolio_text">
                      <a href="https://salesprodirect.com/">SalesPro</a>
                    </p>
                    {/* <p className="portfolio_cat">img1</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="img_five_div">
            <img src={img5} />
            <div className="portfolio_text_holder">
              {" "}
              <div className="portfolio_text_wrapper">
                <p className="portfolio_text">
                  <a href="https://formulaire.kidcab.fr/">Kidcab</a>
                </p>
                {/* <p className="portfolio_cat">img1</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
